import { ModalStatus } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Backdrop, Box, CircularProgress, Stack } from "@mui/material";
import { AppBarHeader, BackButtonLink, NotFoundPage, PageWithHeader } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type ReactElement, useEffect, useRef } from "react";

import { CHECKR_REQUIREMENT_ID, PROFESSIONAL_REFERENCE_REQUIREMENT_ID } from "../Documents";
import { RequirementWorkflowType } from "../Documents/resources/requirements/constants";
import { useDeleteDocument } from "./api/useDeleteDocument";
import { CameraPermissionRequiredDialog } from "./components";
import {
  DocumentUploadCompletedModal,
  DocumentUploadErrorModal,
  DocumentUploadingModal,
} from "./components/documentUploadModals";
import { DeleteDocumentDialog } from "./DeleteDocumentDialog";
import { DocumentDetailsPageFooter } from "./DocumentDetailsFooter/Footer";
import { useDocumentUploadContext } from "./hooks";
import { LoadingDocumentDetailsPage } from "./LoadingDocumentDetailsPage/LoadingDocumentDetailsPage";
import { RequirementDetails } from "./RequirementDetails/RequirementDetails";
import { DocumentDetailsRequirementStatus } from "./RequirementStatus";
import { SelectedFileDisplay } from "./SelectedFilesDisplay";
import { useDocumentDetailsContext } from "./useDocumentDetails";

export function DocumentDetailsPage(): ReactElement {
  const {
    requirementId,
    selectedRequirement,
    isLoadingDocumentProperties,
    documentId,
    isLoadingStripeVerificationSession,
    backLink,
    deleteDocumentModalState,
    refreshQueryAndGoBackToDocumentsPage,
    documentCompletedModalProps,
    documentUploadErrorModalProps,
    documentUploadingModalState,
    documentErroredModalState,
    documentCompletedModalState,
    isDocumentPreviewLoading,
    isCreatingHelloSignSession,
    isLoadingHelloSignDocument,
    fallbackStaticUrl,
    documentDetailsPageContentRef,
    helloSignClientIsOpen,
  } = useDocumentDetailsContext();
  const {
    isUploading,
    permissionDeniedModalState,
    permissionDeniedSource,
    inputFileRef,
    browserOnFileChange,
  } = useDocumentUploadContext();

  const { mutate: deleteDocument } = useDeleteDocument({
    onSuccess: async () => {
      deleteDocumentModalState.closeModal();
      await refreshQueryAndGoBackToDocumentsPage();
    },
  });

  const loadingModalOpenTime = useRef<number | undefined>(undefined);
  const { setModalStatus } = documentUploadingModalState;

  useEffect(() => {
    if (isLoadingHelloSignDocument || isUploading) {
      loadingModalOpenTime.current = Date.now();
      setModalStatus(ModalStatus.OPEN);
    } else {
      if (isDefined(loadingModalOpenTime.current)) {
        logEvent(APP_V2_APP_EVENTS.DOCUMENT_UPLOAD_MODAL_OPEN_TIME, {
          timeToUpload: Date.now() - loadingModalOpenTime.current,
          requirementId: selectedRequirement?.reqId,
          requirementWorkflowType: selectedRequirement?.requirementWorkflowType,
        });
      }

      loadingModalOpenTime.current = undefined;
      setModalStatus(ModalStatus.CLOSED);
    }
  }, [isLoadingHelloSignDocument, setModalStatus, isUploading, selectedRequirement]);

  if (isLoadingDocumentProperties) {
    return <LoadingDocumentDetailsPage backLink={backLink} />;
  }

  if (!isDefined(selectedRequirement)) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Box
        ref={documentDetailsPageContentRef}
        sx={{
          flexDirection: "column",
          height: helloSignClientIsOpen ? "100vh" : "auto",
          display: helloSignClientIsOpen ? "flex" : "none",
          paddingTop: "env(safe-area-inset-top)",
          backgroundColor: "black",
        }}
      />

      {!helloSignClientIsOpen && (
        <PageWithHeader
          appBarHeader={
            <AppBarHeader
              title={selectedRequirement?.name ?? ""}
              leftCta={<BackButtonLink defaultBackTo={backLink} />}
            />
          }
          footer={
            isDefined(fallbackStaticUrl) ||
            [PROFESSIONAL_REFERENCE_REQUIREMENT_ID, CHECKR_REQUIREMENT_ID].includes(
              requirementId ?? ""
            ) ||
            (selectedRequirement?.disableWorkerUpload &&
              selectedRequirement?.requirementWorkflowType ===
                RequirementWorkflowType.DOCUMENT_UPLOAD) ? undefined : (
              <Box
                sx={{
                  marginBottom: 2,
                }}
              >
                <DocumentDetailsPageFooter />
              </Box>
            )
          }
        >
          <Stack
            sx={{
              marginY: 2,
            }}
            spacing={2}
          >
            <DocumentDetailsRequirementStatus />

            <RequirementDetails />

            <SelectedFileDisplay />
          </Stack>

          <Backdrop
            open={
              (documentCompletedModalState.modalIsClosed && isDocumentPreviewLoading) ||
              isCreatingHelloSignSession ||
              isLoadingStripeVerificationSession
            }
            sx={(theme) => ({
              color: theme.palette.primary.main,
              zIndex: theme.zIndex.drawer + 1,
            })}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {isDefined(permissionDeniedSource) && (
            <CameraPermissionRequiredDialog
              modalState={permissionDeniedModalState}
              cameraSource={permissionDeniedSource}
            />
          )}

          <DeleteDocumentDialog
            modalState={deleteDocumentModalState}
            onDelete={() => {
              if (isDefined(documentId)) {
                deleteDocument(documentId);
              }
            }}
          />

          <DocumentUploadingModal modalState={documentUploadingModalState} />
          <DocumentUploadErrorModal
            modalState={documentErroredModalState}
            {...documentUploadErrorModalProps}
          />
          <DocumentUploadCompletedModal
            modalState={documentCompletedModalState}
            {...documentCompletedModalProps}
          />

          <input
            ref={inputFileRef}
            style={{ display: "none" }}
            type="file"
            accept="application/pdf,image/png,image/jpeg,image/jpg"
            onChange={browserOnFileChange}
          />
        </PageWithHeader>
      )}
    </>
  );
}
